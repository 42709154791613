import common from './common_ro.json';
import products from './products_ro.json';
import navigation from './navigation_ro.json';
import filters from './filters_ro.json';
import titles from './titles_ro.json';
import profile from './profile_ro.json';
import cart from './cart_ro.json';
import wishlists from './wishlists_ro.json';
import order from './orders_ro.json';
import footer from './footer_ro.json';
import alerts from './alerts_ro.json';
import errors from './errors_ro.json';
import pages from './pages_ro.json';
import reports from './reports_ro.json';

export default {
  alerts,
  notifications: alerts,
  footer,
  common,
  order,
  orders: order,
  user: profile,
  users: profile,
  products,
  product: products,
  titles,
  navigation,
  filters,
  profile,
  cart,
  wishlists,
  wishlist: wishlists,
  errors,
  pages,
  reports,
};
